import {Spinner} from '@chakra-ui/react';
import React, {FC, ReactElement, Suspense, lazy} from 'react';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';

import AuthGuard from '@app/components/Guard/AuthGuard';
import Dashboard from '@app/layouts/Dashboard';
import SignIn from '@app/page/SignIn';

const WarehouseCarrierSlip = lazy(() => import('@app/page/Warehouse/WarehouseCarrierSlip'));
const WarehouseIndex = lazy(() => import('@app/page/Warehouse/WarehouseIndex'));
const WarehouseInventory = lazy(() => import('@app/page/Warehouse/WarehouseInventory'));
const WarehousePrintBox = lazy(() => import('@app/page/Warehouse/WarehousePrintBox'));
const WarehousePrintLocation = lazy(() => import('@app/page/Warehouse/WarehousePrintLocation'));
const WarehouseWrapper = lazy(() => import('@app/page/Warehouse/WarehouseWrapper'));
const ShopWrapper = lazy(() => import('@app/page/Shop/ShopWrapper'));
const ShopShipping = lazy(() => import('@app/page/Shop/ShopShipping'));
const ShopReception = lazy(() => import('@app/page/Shop/ShopReception'));
const ShopRebut = lazy(() => import('@app/page/Shop/ShopRebut'));
const ShopProductInformation = lazy(() => import('@app/page/Shop/ShopProductInformation'));
const ShopOrderReturn = lazy(() => import('@app/page/Shop/ShopOrderReturn'));
const ShopOrderPackaging = lazy(() => import('@app/page/Shop/ShopOrderPackaging'));
const ShopOrderInformation = lazy(() => import('@app/page/Shop/ShopOrderInformation'));
const ShopPicking = lazy(() => import('@app/page/Shop/ShopPicking'));
const ShopIndex = lazy(() => import('@app/page/Shop/ShopIndex'));
const DashboardIndex = lazy(() => import('@app/page/DashboardIndex'));

const App: FC = (): ReactElement => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <AuthGuard>
          <Dashboard />
        </AuthGuard>
      ),
      children: [
        {
          path: 'shop/:shopSlug/*',
          element: <ShopWrapper />,
          children: [
            {
              index: true,
              element: <ShopIndex />,
            },
            {
              path: 'picking',
              element: <ShopPicking />,
            },
            {
              path: 'shipping',
              element: <ShopShipping />,
            },
            {
              path: 'reception',
              element: <ShopReception />,
            },
            {
              path: 'order-packaging',
              element: <ShopOrderPackaging />,
            },
            {
              path: 'rebut',
              element: <ShopRebut />,
            },
            {
              path: 'product-information',
              element: <ShopProductInformation />,
            },
            {
              path: 'order-return',
              element: <ShopOrderReturn />,
            },
            {
              path: 'order-information',
              element: <ShopOrderInformation />,
            },
          ],
        },
        {
          path: 'warehouse/*',
          element: <WarehouseWrapper />,
          children: [
            {
              index: true,
              element: <WarehouseIndex />,
            },
            {
              path: 'inventory',
              element: <WarehouseInventory />,
            },
            {
              path: 'print-location',
              element: <WarehousePrintLocation />,
            },
            {
              path: 'print-box',
              element: <WarehousePrintBox />,
            },
            {
              path: 'carrier-slip',
              element: <WarehouseCarrierSlip />,
            },
          ],
        },
        {
          path: '',
          element: (
            <Suspense>
              <DashboardIndex />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: '/login',
      element: <SignIn />,
    },
  ]);

  return (
    <>
      <RouterProvider router={router} fallbackElement={<Spinner />} />
    </>
  );
};

export default App;
