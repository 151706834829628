import {Box, Center, Flex, Portal, Spinner, useColorModeValue} from '@chakra-ui/react';
import React, {FC, ReactElement, Suspense, useState} from 'react';
import {Outlet} from 'react-router-dom';

import MainPanel from '@app/layouts/MainPanel';
import AdminNavbar from '@app/layouts/Navbar/AdminNavbar';
import PanelContainer from '@app/layouts/PanelContainer';
import PanelContent from '@app/layouts/PanelContent';
import {SidebarContext} from '@app/layouts/Sidebar/SideBarContext';
import Sidebar from '@app/layouts/Sidebar/Sidebar';
import {usePrinterListQuery} from '@app/store/camalo/printerApi';
import {useUserAccessQuery} from '@app/store/camalo/userAccess/userAccessApi';

const Dashboard: FC = (): ReactElement => {
  const {data: userAccess, isLoading, isError} = useUserAccessQuery();
  const {data: printerList = [], isLoading: isLoadingPrinterList} = usePrinterListQuery();

  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(275);
  const [fixed] = useState(false);
  let bgBoxHeight = '40vh';
  let bgBoxColor = useColorModeValue(
    'linear-gradient(135deg, hsla(202, 27%, 45%, 1) 0%, hsla(213, 77%, 14%, 1) 100%);',
    'navy.900',
  );

  const loading = () => {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Center>
          <Spinner size="xl" />
        </Center>
      </Flex>
    );
  };

  if (isError) {
    return <div> There is some problem! </div>;
  }

  if (isLoading || isLoadingPrinterList) {
    return loading();
  }

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          sidebarWidth,
          setSidebarWidth,
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Box minH={bgBoxHeight} w="100%" position="absolute" bg={bgBoxColor} top="0" />
        <Sidebar userAccess={userAccess!} />
        <MainPanel
          w={{
            base: '100%',
            xl: `calc(100% - ${sidebarWidth}px)`,
          }}
        >
          <Portal>
            <AdminNavbar fixed={fixed} userAccess={userAccess!} />
          </Portal>

          <PanelContent>
            <PanelContainer>
              <Flex direction="column" pt={{base: '120px', md: '75px'}}>
                <Suspense fallback={loading()}>
                  <Outlet context={{userAccess, printerList}} />
                </Suspense>
              </Flex>
            </PanelContainer>
          </PanelContent>
        </MainPanel>
      </SidebarContext.Provider>
    </Box>
  );
};

export default Dashboard;
